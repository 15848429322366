import { Layout, LegacyCard, Page } from "@shopify/polaris";
import { ConditionalImage } from "@smartrr/shared/components/ConditionalImage";
import React, { useMemo } from "react";

import { useMyShopifyDomainSelector } from "@vendor-app/app/_state/reducers/shopify";

const CREATE_DISCOUNTS_PATH = "/admin/discounts/new";

export function AdminDiscountsRoute(): JSX.Element {
  const myShopifyDomain = useMyShopifyDomainSelector();

  return (
    <React.Fragment>
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <LegacyCard sectioned title="Subscription Discounts">
              <LegacyCard.Section>
                Good news! Smartrr allows your subscription discounts and your Shopify discounts to both be
                created <b>in the Shopify Admin portal</b>.
                <br />
                <br />
                To create a <b>discount code</b> for your subscription products follow{" "}
                <b>
                  <a
                    href={`https://${myShopifyDomain}${CREATE_DISCOUNTS_PATH}`}
                    target="_blank"
                    style={useMemo(
                      () => ({
                        cursor: "pointer",
                        color: "darkorange",
                        textShadow: "0px 0px 1px rgba(0,0,0,.4)",
                        textDecoration: "underline",
                      }),
                      []
                    )}
                    rel="noreferrer"
                  >
                    this link
                  </a>
                </b>{" "}
                to your Shopify admin page.
                <br />
                <br />
                Once on this page, select &quot;Subscription&quot; option in the &quot;Purchase Type&quot; section
                that you see highlighted in the image below:
              </LegacyCard.Section>
              <LegacyCard.Section>
                <a href={`https://${myShopifyDomain}${CREATE_DISCOUNTS_PATH}`} target="_blank" rel="noreferrer">
                  <ConditionalImage
                    highlight
                    desktopImage="admin/Discounts_Desktop.png"
                    mobileImage="admin/Discounts_Mobile.png"
                    style={useMemo(() => ({ maxWidth: "100%", width: 600 }), [])}
                  />
                </a>
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
        </Layout>
      </Page>
    </React.Fragment>
  );
}
